.usa-combo-box--bordered {
    border: solid 1px #5c5c5c;
    &:focus:not(:focus-visible) {
        outline: none;
    }
}
.usa-combo-box__list-mt-1 {
    margin-top: 1px;
}
.usa-input-combo-box--unstyled {
    border-style: hidden;
    background-color: transparent;
    height: 2.5rem;
    padding: 0.5rem;
    appearance: none;
    margin-bottom: 0;
    width: 100%;
}
.usa-combo-box input::placeholder { // to match with regular dropdown placeholder text color
    color: var(--theme-color-text-ink);
    opacity: 1; /* Firefox */
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: var(--theme-color-text-ink);
    }
    ::-ms-input-placeholder { /* Microsoft Edge */
        color: var(--theme-color-text-ink);
    }
}
.usa-combo-box__clear-input--sticky-top {
    position: absolute;
    height: 2.5rem;
    top: 0;
}
.usa-combo-box__toggle-list--sticky-top {
    position: absolute;
    height: 2.5rem;
    top: 0;
}

.usa-combo-box__list .usa-combo-box__list-status {
    padding: 0.5rem;

    .usa-combo-box__list-status--loading {
        display: flex;
        align-items: center;
        .afp-spinner {
            margin-right: 0.2rem;
        }
    }
}

[role="option"] {
    .usa-checkbox__label {
        margin-top: 0.35rem;
        margin-bottom: 0.35rem;
    }

    &.focused {
        background: var(--theme-color-primary-lighter);
    
        .usa-checkbox__label {
            background:var(--theme-color-primary-lighter) !important;
        }
    }
}